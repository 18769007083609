.offcanvas-header{
    justify-content: right;
}
.offcanvas-header button{
    color: #C1563A !important;
}
.offconvas-ul{
    color: black;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.offconvas-ul li{
    list-style: none;
}
.offconvas-ul li:hover{
    color: #C1563A;
}
.shair-btn{
    margin-top: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 20px;
    background-color: #C1563A;
    color:#FFFFFF;
    font-size: 18px;
    width: 100%;

}
.shair-btn:hover{
    background-color: transparent;
    border-color:  #C1563A;
    color: #C1563A;
    padding: 5px 20px;
    border-radius: 5px;
}
