.carousel-bg{
    background-color: #E9E5DB;
    width: 100%;
    height: auto;
    padding: 50px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.carousel-content{
    display: flex;
    flex-direction: column;
    gap: 70px;
}
.p-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 51px;
color: #C1563A
}
.carousel-content h2{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 39px;

color: #C1563A;
}
.down-arrow-carousel{
    display: flex;
    justify-content: center;
    align-items: center;

}
.down-arrow-carousel a{
    font-size: 50px;
    /* cursor: pointer; */
    color: #000000;
}