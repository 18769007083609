
.mainNav{
    width: 100%;
    background-color: #000000;
    padding: 20px;
}
.navContentDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
}
.navContent{
    list-style: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.navContent li{
    list-style: none;
    margin-right: 20px;
    color:#FFFFFF;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-size: 18px;
}
.navContent li:hover{
    color:#C1563A
}
.nav-btn{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    background-color: #C1563A;
    color:#FFFFFF;
    font-size: 18px;
}
@media screen and (max-width: 567px){
    .nav-btn{
        padding: 8px 3px;
        font-size: 8px;
    }
}
@media screen and (max-width: 768px){
    .nav-btn{
        padding: 12px 24px;
        font-size: 10px;
    }
}
.nav-btn:hover{
    background-color: transparent;
    border-color:  #C1563A;
    color: #C1563A;
    padding: 5px 20px;
    border-radius: 5px;
}
.right-content{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
.right-content p{
    color: #C1563A;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}
.right-content p:hover{
    color: #FFFFFF;
}
.navContentDiv i{
    color: #C1563A;
    font-size: 30px;
    
}
