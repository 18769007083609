.stories-main-div{
    padding: 50px 0px;
}
.stories{
    display: flex;
    flex-direction: column;
gap: 20px;
}
.stori-title{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 45px;
/* line-height: 80px; */

color: #C1563A;
}
.stories-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 44px;

color: #C1563A;
}
.stori-card{
    background: #E9E5DB;
    height: 400px;
}
.card-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000
}
.card-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
text-align: justify;

color: #000000;
}