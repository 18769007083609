.rhap_progress-section{
    width: 70% !important;
}
.rhap_controls-section{
    width: 80%;
}
.rhap_container{
    width: 80%;
    /* background-color: transparent; */
    height: 80px;
    color: white;
}.rhap_additional-controls{
    display: none;
}