.diversion-main-div{
    padding: 50px 0px;
    background: #E9E5DB;
    width: 100%;
height: auto;
}
.l-1{
    /* position: absolute; */
width: 251px;
height: 0px;
/* left: 251px;
top: 2310px; */

border: 3px dashed #000000;
/* transform: rotate(-180deg); */
}
.l-2{
    position: relative;

/* height: 100% !important; */
/* */
/* top: 1832px; 
left: 251px; */

border: 3px dashed #000000;
transform: rotate(90deg);
}
.circle-1{
    margin-left: 224px;
    position: absolute;
    margin-top: 50px;
}
.circle-2{
    margin-left: 224px;
    position: absolute;
    margin-top: 70px;
}
.circle-3{
    margin-left: 224px;
    position: absolute;
    margin-top: 200px;
}
.hr-line{
    background-color: transparent;
    border: 2px dotted #000000;
transform: rotate(90deg);
}
.divided-punjab{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.dp-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 28px;
/* line-height: 34px; */

color: #C1563A;
}
.dp-paragraph{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 18px;
/* line-height: 22px; */
text-align: justify;

color: #000000;
}
.detention-tex{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 60px;
/* line-height: 80px; */
color: #000000;
}
.content-gap{
    display: flex;
    flex-direction: column;
    align-items: center;
gap: 20px;
}
