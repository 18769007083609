.prosons-main-div{
    padding: 50px 0px;
}
.prisons-title{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 45px;
/* line-height: 80px; */

color: #000000;
}
.prisons-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 27px;
text-align: justify;

color: #000000;

}