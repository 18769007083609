.chapter-main-div{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    padding: 50px 0px;
}
.chapter-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;

color: #000000;
}
.punjab-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 39px;

color: #000000;
}
.paragraph{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 27px;
text-align: justify;

color: #000000;
}
.audio-podcast{
    width: 100%;
height: auto;
background: #D9D9D9;
display: flex;
flex-direction: column;
justify-content: center;
padding: 10px 15px;
/* align-items: center; */

}
.podcast-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 27px;
color: #000000;
}
.podcast-paragraph{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
text-align: justify;

color: #000000;
}
.col-content{
    display: flex;
    gap: 20px;
}
.col-images{
    display: flex;
    flex-direction: column;
    gap: 25px;
}